import headPosterStyles from './head-poster.module.css'
import React from "react"

export default class HeadPoster extends React.Component{
  render() {
    return(
      <div className={headPosterStyles.posterContainer}>
        <div>
          <img className={headPosterStyles.logoImg} alt="cugelogo" src={require("../images/logo1.png")}></img>
        </div>
      </div>
    )
  }
}